import React from "react";
import attorneyData from "../sitedata/attorneyData";
import { Link, useParams } from "react-router-dom";
const Attorneys = () => {
  const attorneys = attorneyData.map((each) => (
    <div className="col-lg-4 col-md-6 col-sm-12" key={each.id}>
      <div className="attorneys-info attorneys-hv-link">
        <div className="attorneys-avatar hv-link-content">
          <div className="image">
            <Link to={`/attorneycv/${each.name}`}>
              <img src={each.image} alt="images" />
            </Link>
          </div>
        </div>
        <div className="attorneys-content">
          <div className="content-wrap">
            <h3 className="name">
              <Link to={`/attorneycv/${each.name}`}>
{each.name}</Link>
            </h3>
            <p className="position">{each.position}</p>
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div className="attorneys attorneys-style3">
      <div className="container">
        <div className="title-section text-center">
          <h3 className="flat-title">Our People</h3>
          <p className="sub-title">
            All of the attorneys in our firm are first-rate professionals with
            substantial legal backgrounds.
          </p>
        </div>
        <div className="row">{attorneys}</div>
      </div>
    </div>
  );
};

export default Attorneys;
