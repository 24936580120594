import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import data from "../../sitedata/articlesData/articleData";

const News = () => {
  const defaultImg =
    "https://images.unsplash.com/photo-1593115057322-e94b77572f20?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80";
  return (
    <>
      <div className="practice-area practice-area-style1">
        <div className="container">
          <div className="title-section text-center">
            <h3 className="flat-title">Private News</h3>
          </div>
          <div className="practice-area-wrap">
            <div className="row">
              {data &&
                data.map((each) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 col-12"
                    key={each.id}
                  >
                    <div className="image-box-law">
                      <div className="featured-post">
                        <div className="entry-image">
                          <Link to={`/newsdesc/${each.id}`}>
                            {each.img == "" ? (
                              <img
                                id="articles-Image"
                                src={defaultImg}
                                alt="images"
                              />
                            ) : (
                              <img
                                src={each.imgUrl}
                                alt="images"
                                id="articles-Image"
                              />
                            )}
                          </Link>
                        </div>
                      </div>
                      <div className="content-law py-4 text-left">
                        <h4 className="py-2">
                          <Link
                            className="name"
                            id="article-link"
                            style={{
                              fontSize: "20px",
                              textTransform: "capitalize",
                            }}
                            to={`/newsdesc/${each.id}`}
                          >
                            {each.title.substring(0, 67)}...
                          </Link>
                        </h4>
                        <Link
                          style={{ textTransform: "capitalize" }}
                          to={`/newsdesc/${each.id}`}
                          id="article-link"
                        >
                          read more
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
