export default [
  {
    id: 1,
    icon: "images/practice/11.png",
    title: "Best Legal Security",
  },
  {
    id: 2,
    icon: "images/practice/12.png",
    title: "Top Skilled Attorney",
  },
  {
    id: 3,
    icon: "images/practice/13.png",
    title: "All Time Support",
  },
  {
    id: 4,
    icon: "images/practice/14.png",
    title: "Resonable Pricing",
  },
];
