import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminDashboard from "./adminDashboard";
import AdminLogin from "./adminLogin";
import { useNavigate } from "react-router-dom";
const Admin = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.isLoggedIn);
  const [render, setRender] = useState(false);
  useEffect(() => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      setRender(true);
    } else {
      //   navigate("/");
    }
  }, [isLoggedIn]);
  return <>{render ? <AdminDashboard /> : <AdminLogin />}</>;
};

export default Admin;
