import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
const Form = () => {
  const [sent, setIsSent] = useState(false);
  const form = useRef();
  const sendForm = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qz4mi5w",
        "template_qh275bd",
        form.current,
        "4-3mciVijUFC0HE--"
      )
      .then(
        (result) => {
          e.target.reset();
          setIsSent(true);
          setTimeout(() => {
            setIsSent(false);
          }, 2000);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  return (
    <form ref={form} onSubmit={sendForm} className="form-evaluation">
      <div className="wrap-evaluation">
        <h5 className="title">Get In Touch</h5>
        <div className="your-name mg-text">
          <input
            name="name"
            type="text"
            className="your-name"
            placeholder="Your name"
          />
        </div>
        <div className="your-email mg-text">
          <input
            name="email"
            type="text"
            className="your-email"
            placeholder="Your email"
          />
        </div>
        <div className="your-phone mg-text">
          <input
            name="number"
            type="number"
            className="your-phone"
            placeholder="Your phone no"
          />
        </div>
        <div className="message-wrap mg-text">
          <textarea
            name="message"
            id="message"
            rows="8"
            placeholder="Your message"
          ></textarea>
        </div>
        <div className="fl-btn">
          <button className="hvr-vertical">
            {sent ? "Message Sent!" : "Send Message"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
