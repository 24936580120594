import React from "react";
import { useParams } from "react-router-dom";
import attorneyData from "../../sitedata/attorneyData";
const AttorneyCv = () => {
  const { name } = useParams();

  const each = attorneyData.find((attorney) => attorney.name === name);
  console.log(each);
  return (
    <div className="attorneys-single">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="attorneys-single-warp d-md-flex">
              <div className="col-left">
                <div className="personal-details">
                  <div className="featured-post">
                    <div className="entry-image">
                      <img src={each.image} alt="images" />
                    </div>
                  </div>
                  <ul className="attorneys-info-sn">
                    <li>
                      <h3 className="name-info name-size">{name}</h3>
                      <p>{each.position}</p>
                    </li>
                    <li>
                      <div className="name-info">Address</div>
                      <p>{each.address}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-right">
                <div className="introduce-attorneys">
                  <div className="text">
                    <p>{each.about}</p>
                  </div>
                </div>
                <div className="flat-question">
                  <div className="accordion">
                    {each.educationBg && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center active">
                          <div className="icon">
                            <i
                              className="fa fa-thumb-tack"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            Education
                          </h5>
                        </div>
                        <div className="toggle-content">
                          {each.educationBg.map((each) => (
                            <div className="skillbar-item" key={each.id}>
                              <h6 className="skillbar-title">{each.title}</h6>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {each.career && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i
                              className="fa fa-address-book-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            Career Objectives
                          </h5>
                        </div>
                        <div className="toggle-content">
                          <div className="skillbar-item">
                            <h6 className="skillbar-title">{each.career}</h6>
                          </div>
                        </div>
                      </div>
                    )}
                    {each.workEx && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i
                              className="fa fa-address-book-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            Experience
                          </h5>
                        </div>
                        <div className="toggle-content">
                          {each.workEx.map((each) => (
                            <div className="skillbar-item" key={each.id}>
                              <h6 className="skillbar-title">{each.title}</h6>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {each.hobbies && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i
                              className="fa fa-folder-o"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            Hobbies
                          </h5>
                        </div>
                        <div className="toggle-content">
                          <div className="skillbar-item">
                            <h6 className="skillbar-title">{each.hobbies}</h6>
                          </div>
                        </div>
                      </div>
                    )}

                    {each.REFEREES && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i className="" aria-hidden="true"></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            REFEREES
                          </h5>
                        </div>
                        <div className="toggle-content">
                          <div className="skillbar-item">
                            <h6 className="skillbar-title"> {each.REFEREES}</h6>
                          </div>
                        </div>
                      </div>
                    )}
                    {each.skill && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i className="" aria-hidden="true"></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            Skills
                          </h5>
                        </div>
                        <div className="toggle-content">
                          {each.skill.map((each) => (
                            <div className="skillbar-item" key={each.id}>
                              <h6 className="skillbar-title">{each.title} </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {each.EXTRACURRICULAR && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i className="" aria-hidden="true"></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            EXTRACURRICULAR
                          </h5>
                        </div>
                        <div className="toggle-content">
                          <div className="skillbar-item" key={each.id}>
                            <h6 className="skillbar-title">
                              {each.EXTRACURRICULAR}{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    )}
                    {each.awards && (
                      <div className="accordion-toggle line">
                        <div className="toggle-title d-flex align-items-center">
                          <div className="icon">
                            <i className="" aria-hidden="true"></i>
                          </div>
                          <h5 className="name d-flex align-items-sm-center">
                            Awards
                          </h5>
                        </div>
                        <div className="toggle-content">
                          {each.awards.map((each) => (
                            <div className="skillbar-item" key={each.id}>
                              <h6 className="skillbar-title">{each.title} </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttorneyCv;
