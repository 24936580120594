import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenu, setIsMenu] = useState(false);
  return (
    <>
      <div className="top-bar py-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <ul className="flat-information d-lg-flex align-items-center">
                <li className="email">
                  <a
                    href="mailto:contact@patienceimoisilichambers.com"
                    title="Email"
                  >
                    contact@patienceimoisilichambers.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="flat-contact-us d-lg-flex align-items-center">
                <a href="tel:+2349158685156" className="phone">
                  09158685156
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header id="header" className="header bg-color py-0">
        <div className="container">
          <div className="flex-header">
            <div id="logo" className="logo">
              <a href="/" title="Logo">
                <img
                  src="/logo.png"
                  data-width="211"
                  data-height="34"
                  alt="images"
                />
              </a>
            </div>
            <div className="content-menu">
              <div className="nav-wrap">
                <div
                  className={isMenu ? "btn-menu active" : "btn-menu"}
                  onClick={() => setIsMenu(!isMenu)}
                >
                  <span></span>
                </div>
                <nav
                  id={isMenu ? "mainnav-mobi" : "mainnav"}
                  className="mainnav"
                >
                  <ul className="menu">
                    <li>
                      <Link to="/" onClick={() => setIsMenu(false)}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={() => setIsMenu(false)}>
                        About Us
                      </Link>
                    </li>

                    <li>
                      <Link to="/articles" onClick={() => setIsMenu(false)}>
                        Articles
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
