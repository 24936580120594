import React from "react";
import featuredData from "../sitedata/featuredData";

const Featured = () => {
  const features = featuredData.map((each) => (
    <div className="col-md-6 col-sm-6 col-12" key={each.id}>
      <div className="iconbox">
        <div className="iconbox-icon">
          <img src={each.icon} alt="images" />
        </div>
        <div className="iconbox-content">
          <h5 className="title">{each.title}</h5>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <section className="featured featured-style3">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="content">
                <h4 className="heading">REASON TO CHOOSE US</h4>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="iconbox-features type3">
                <div className="row">{features}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Featured;
