import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import newsData from "../../sitedata/articlesData/articleData";
const NewDesc = () => {
  const { id } = useParams();

  const eachNews = newsData.find((news) => news.id === id);
  console.log(eachNews);
  const defaultImg =
    "https://images.unsplash.com/photo-1593115057322-e94b77572f20?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80";
  return (
    <>
      {eachNews && (
        <div className="practice-single">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="content-wrap clearfix">
                  <div className="featured-post">
                    <div className="entry-image">
                      {eachNews.img == "" ? (
                        <img src={defaultImg} alt="images" />
                      ) : (
                        <img src={eachNews.img} alt="images" />
                      )}
                    </div>
                  </div>
                  <div className="overview content-practice-single mg-practice-single">
                    <h3 className="title">
                      <a href="#">{eachNews.title}</a>
                    </h3>
                    <p>{eachNews.desc}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewDesc;
