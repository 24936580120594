import { Outlet } from "react-router-dom";
import Footer from "./ui/navigation/footer";
import Header from "./ui/navigation/header";
import { ToastContainer } from "react-toastify";
import AttorneyCv from "./ui/pages/attorneyCv";
function App() {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
