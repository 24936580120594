import React from "react";
import AboutDesc from "../../components/about-desc";
import Attorneys from "../../components/attorneys";

const About = () => {
  return (
    <>
      <AboutDesc />
      <div className="facts">
        <div className="container">
          <div className="flat-counter">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="counter">
                  <div className="content-counter">
                    <div className="numb-count-wrap">
                      <span className="numb-count text-white">12</span>
                    </div>
                    <div className="name-count">Years of Experience</div>
                    <div className="icon-count">
                      <img src="images/home1/09.png" alt="images" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="counter">
                  <div className="content-counter">
                    <div className="numb-count-wrap">
                      <span className="numb-count text-white">3</span>
                    </div>
                    <div className="name-count">Total Attornies</div>
                    <div className="icon-count">
                      <img src="images/home1/10.png" alt="images" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="counter">
                  <div className="content-counter">
                    <div className="numb-count-wrap">
                      <span className="numb-count text-white">2000</span>
                      <span className="numb-bs text-white">+</span>
                    </div>
                    <div className="name-count">Happy Clients</div>
                    <div className="icon-count">
                      <img src="images/home1/11.png" alt="images" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="counter">
                  <div className="content-counter">
                    <div className="numb-count-wrap">
                      <span className="numb-count text-white">99</span>
                      <span className="numb-bs text-white">%</span>
                    </div>
                    <div className="name-count">Success Rate</div>
                    <div className="icon-count">
                      <img src="images/home1/12.png" alt="images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Attorneys />
    </>
  );
};

export default About;
