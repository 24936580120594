export default [
  {
    id: 1,
    name: "Abraham Imoisili, Esq. (K.S.M.)",
    position: "MANAGING PARTNER",
    image: "/images/attorneys/01.png",
    address: "32, Adegbola Street, Anifowoshe, Ikeja, Lagos State",
    about:
      "Abraham Osereimen Imoisili is a Legal Practitioner called to the Nigerian Bar in January, 2012. He started his career in the office of CHIEF MUSA TOLANI & CO. as a Youth Corper with the National Youth Service Corps from February, 2012 till December, 2012 in the city of Aba in Abia State of Nigeria. Thereafter he joined the office of MUDASHIRU AIRAHI & CO located at Yaba, Lagos State in January 2013. In April, 2014 he set up his own law practice together with then partner Anthony Okoh at the law firm of IMOISILI & OKOH based in Ikeja, Lagos State of Nigeria. He thereafter joined the firm of BOLS ATTORNEYS & SOLICITORS as Head of Chambers in November, 2021. He has over 10 years experience in litigation with matters conducted at the State High Courts (Lagos, Abia, Anambra, Akwa Ibom, Rivers and Enugu), Federal High Courts (Calabar, Umuahia and Lagos Divisions), the National Industrial Court of Nigeria (Lagos Division) and the Court of Appeal (Lagos Division) of which he has 5 personal matters currently pending at the Lagos Division of the Court of Appeal. He has also appeared at the Supreme Court albeit led by his then Principal, Chief Musa Tolani in 2012. He also has a flair for lecturing which has led him to lecture several undergraduate law courses including labour law at MEGASTRAT CONSULTING located at Muri Okunola Street, Victoria Island Lagos for students of the CHARTERED INSTITUTE OF PERSONNEL MANAGEMENT (CIPM) programme and also law courses at private study centres for students of the National Open University of Nigeria Faculty of law. As a practitioner, Mr. Imoisili has acted as Legal Adviser/external solicitor to corporate bodies including Legend Logistics Limited makers of Skyshoot Energy Drink; Eri-ere Partners & Investment Limited amongst others. He is married with 2 sons, a devout Catholic and a Knight of St. Mulumba.",
    educationBg: [
      {
        id: "1",
        title: "Nigerian Law School, Lagos Campus (B.L.) - 2011",
      },
      {
        id: "2",
        title: "Ambrose Alli University, Ekpoma (L.L.B) - 2010",
      },
      {
        id: "3",
        title: "Integrity National High School, Benin City (S.S.C.E.) - 2004",
      },
      {
        id: "4",
        title: "Brother Pius Nursery and Primary School - 1998",
      },
    ],
    workEx: [
      {
        id: "1",
        title:
          "Bols Attorneys & Solicitors - (Head of Chambers, November 2021)",
      },
      {
        id: "2",
        title: "Imoisili & Okoh – Legal Practitioners - (Partner, 2014 - 2021)",
      },
      {
        id: "3",
        title:
          "Pius Imera Study Centre Jibowu Lagos - (NOUN Study Centre Facilitator, 2014 - 2018)",
      },
      {
        id: "4",
        title:
          " Megatrust Consulting, Victoria island Lagos - (C.I.P.M. lecturer/facilitator 2014 - 2018)",
      },
      {
        id: "5",
        title:
          "Mudashiru Airahi & Co., Lagos State - (Associate, January, 2013 – February, 2014)",
      },
      {
        id: "6",
        title:
          "Chief Musa Tolani & Co., Aba Abia State - (Junior Associate, January, 2012 – November, 2012)",
      },
    ],
    hobbies: "Reading, Politics, Social-Economics and Traveling",
  },
  {
    id: 2,
    name: "Amarachi Veronica Okolie",
    position: "ASSOCIATE",
    image: "/images/attorneys/02.png",
    address: "No 4 Balogun street, gbara Jakande lekki Ajah Lagos.",
    about:
      "I am a Legal Practitioner who is passionate about achieving excellent results and making impact. I exhibited leadership qualities through handling of projects, leveraging on effective communication and keen attention for details. I also have interest in litigation, arbitration, commercial law and corporate law practice. Aiming to secure a friendly and innovative workplace on a graduate scheme which will provide commercial exposure and opportunity for career progression",
    educationBg: [
      {
        id: "1",
        title:
          "Nigeria Law School, Yenagoa Campus, Yenagoa, Bayelsa		Sept 2021 – Sept 2022 (BL second class lower division)",
      },
      {
        id: "2",
        title:
          "Imo State University Jan 2015 – Sept 2020 Degree Awarded: LLB (Second Class Upper division)",
      },
    ],
    workEx: [
      {
        id: "1",
        title: "Law School Extern",
      },
      {
        id: "2",
        title: "CIA Onwuegbusi & Associate April 2022 – May 2022",
      },
      {
        id: "3",
        title:
          "I was engaged in client interview and proffered legal advice in the course of interviewing the clients",
      },
      {
        id: "4",
        title:
          " I regularly visit the court registry to file processes on behalf of my principal ",
      },
      {
        id: "5",
        title:
          " I drafted motions and gave presentation about some areas of legal importance  ",
      },
      {
        id: "6",
        title: "Law School Extern",
      },
      {
        id: "7",
        title: "High Court 2, Onitsha, Anambra State					March 2022 – April 2022 ",
      },
      {
        id: "8",
        title:
          " I observed court proceedings for the given six (6) weeks non-stop    ",
      },
      {
        id: "9",
        title:
          " I took records of court proceedings and observed the demeanour of the Judge, Lawyers, and the settings of court ",
      },
      {
        id: "10",
        title:
          " I had the opportunity of reviewing case files and judicial precedence from the rulings of court ",
      },
    ],
    skill: [
      {
        id: "1",
        title: "Leadership Skills",
      },
      {
        id: "2",
        title: " Communication Skills",
      },
      {
        id: "3",
        title: "Teamwork and networking Skills",
      },
      {
        id: "4",
        title: " Ability to work alone under short deadlines",
      },
      {
        id: "5",
        title: " Emotional intelligence  ",
      },
    ],
    EXTRACURRICULAR:
      "Participated in an inter class mock trial competition where I played the role of lead prosecution counsel for my team",
    awards: [
      {
        id: "1",
        title:
          "Certificate of Participation as a witness in a rape case 	 		July, 2022 (Organised by the Nigerian Law School, Yenagoa Campus, Bayelsa State)",
      },
    ],
  },
  {
    id: 3,
    name: "Esther Monisola Agbonjinmi",
    position: "ASSOCIATE",
    image: "/images/attorneys/03.png",
    address: "BT Street, House 32, Pako Bus-Stop, Shagari Estate, Ipaja, Lagos",
    career:
      "My goal is to become associated with a firm where I can utilize and hone my skills and gain further experience while enhancing the firm’s productivity and reputation.",
    skill: [
      {
        id: "1",
        title: "Adept at drafting legal documents",
      },
      {
        id: "2",
        title: " Proficient in Microsoft Office tools",
      },
      {
        id: "3",
        title: "Active listener and strong communicator (written and oral)",
      },
      {
        id: "4",
        title:
          " Strong interpersonal skills and ability to work with diverse teams",
      },
      {
        id: "5",
        title: " Great organizational skill",
      },
    ],
    educationBg: [
      {
        id: "1",
        title:
          "Qualifying Certificate (BL) Nigerian Law School, Abuja Campus, Bwari, Abuja Class of degree – Second Class lower - 2019",
      },
      {
        id: "2",
        title:
          "Bachelor of Law (LLB) Obafemi Awolowo University, Ile-Ife, Osun State Class of degree – Second Class lower - 2018",
      },
      {
        id: "3",
        title:
          "Senior secondary Certificate Examination Federal Science and Technical College, Yaba, Lagos - 2010",
      },
    ],
    workEx: [
      {
        id: "2",
        title:
          "LEGALPOINT HOUSE OF LAW - Legal Associat Jan 2021 – May2021.  Responsibilities: Prepare and draft Ghana Law Report,	Prepare and draft legal documents and agreements, Legal Research",
      },
      {
        id: "3",
        title:
          "KOLADE AKANLE & CO - Internship September – December 2020 Responsibilities: Assist in preparing for court cases, Assist in drafting legal documents, Legal research",
      },
      {
        id: "4",
        title:
          " WYS SOLICITORS National Youth Service Nov 2019 – Sept 2020 Responsibilities:Assist in registration of businesses and corporations., Assist in obtaining the appropriate licenses for clients in the gaming and gambling industry, Assist in ensuring compliance with licensing and regulatory framework with the relevant gaming authorities.",
      },
    ],
    hobbies: "Reading, Organizing and Traveling",
  },
];
