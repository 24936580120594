export default [
  {
    id: 1,
    icon: "images/home1/01.png",
    title: "Short Time Result",
    class: "iconbox iconbox-1",
  },
  {
    id: 2,
    icon: "images/home1/02.png",
    title: "We Stand For Justice",
    class: "iconbox iconbox-2",
  },
];
