import { configureStore } from "@reduxjs/toolkit";
import isLoggedInSlice from "./slice";

const store = configureStore({
  reducer: {
    isLoggedIn: isLoggedInSlice,
  },
});

export default store;
