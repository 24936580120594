export default [
  {
    id: "1",
    title:
      "Judgement Alert:Federal High Court awards N2million damages against",
    desc: "Federal High Court awards N2million damages against NDLEA and in favour of an Applicant in a Fundamental Rights case - Mr Jack Alele. The Court per Hon. Justice Lifu held that it is was wrong for any security agency to freeze the account of a citizen without Court order. The Applicant was represented by our team of Legal Practitioners at Patience Imoisili Chambers.",
    img: "",
  },
  {
    id: "2",
    title: "Judgement alert: Court of Appeal Lagos Division refuses the Appeal",
    desc: "Court of Appeal Lagos Division refuses the Appeal filed by Honourable Olanrewaju Osundairo challenging the decision of the People's Democratic Party to substitute his name for Mr. Adewale Gbenga Oyeniyi on the grounds that same is statute barred purportedly filed outside the 14 days Constitutional period. The Appellant had argued and the Court however was silent on the implication of the submission of the name of Mr. Adewale Gbenga Oyeniyi by the PDP on the 17th of June, 2022 whereas the suit was filed on the 22nd day of June, 2022 which is 5 days and not outside the 14 days period. The Appellant who was represented by our team in Patience Imoisili Chambers has since approached the Supreme Court on further appeal.",
    img: "",
  },
];
