import React from "react";
import iconboxData from "../sitedata/iconboxData";

const Iconbox = () => {
  const iconboxContent = iconboxData.map((each) => (
    <div className={each.class} key={each.id}>
      <div className="bck-stand"></div>
      <div className="bnc">
        <div className="iconbox-wrap">
          <div className="iconbox-icon">
            <img src={each.icon} alt="images" />
          </div>
          <div className="iconbox-content">
            <h5 className="title" style={{ fontSize: "2em" }}>
              {each.title}
            </h5>
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <section className="iconbox-home clearfix">
        <div className="container">
          <div className="iconbox-features type2">{iconboxContent}</div>
        </div>
      </section>
    </>
  );
};

export default Iconbox;
