import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../features.js/slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AdminDashboard = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imgUrl, setimgUrl] = useState("");
  const navigate = useNavigate();
  const sumbitArticle = (e) => {
    e.preventDefault();
    const article = {
      title,
      content,
      imgUrl,
    };
    // fetch(" http://localhost:3002/articles", {
    fetch("https://chamber-articles-api.onrender.com/articles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(article),
    }).then(() => {
      toast.success("Article Added");
      setTitle("");
      setContent("");
      setimgUrl("");
    });
  };
  const dispatch = useDispatch();
  return (
    <>
      <form
        action="#"
        className="container form-evaluation my-5"
        onSubmit={sumbitArticle}
      >
        <div className="wrap-evaluation">
          <h5 className="title">Add Article</h5>
          <p className="text-center">
            Check Below For Preview Before Uploading.
          </p>

          <div className="mg-text">
            <input
              type="text"
              className="article-title"
              placeholder="Paste Your Image Link Here..."
              value={imgUrl}
              onChange={(e) => setimgUrl(e.target.value)}
            />
          </div>
          <div className="mg-text">
            <input
              type="text"
              className="article-title"
              placeholder="Article Title"
              value={title}
              required
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="message-wrap mg-text">
            <textarea
              name="message"
              id="message"
              rows="8"
              placeholder="Article Text Content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="fl-btn">
            <button className="hvr-vertical">Add Article</button>
          </div>
        </div>

        <p className="text-center">
          <button
            className="logout my-4"
            onClick={() => {
              dispatch(logout());
              navigate("/");
              toast.success("You Logged Out");
            }}
          >
            Logout
          </button>
        </p>
      </form>

      <div className="practice-single">
        <div className="container">
          <h1 className="text-center" style={{ fontSize: "3em" }}>
            Preview
          </h1>
          <p className="text-center mb-5">Read Carefully For Grammatic Error</p>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-wrap clearfix">
                <div className="featured-post">
                  <div className="entry-image">
                    <img src={imgUrl} alt="Insert An Image Here" />
                  </div>
                </div>
                <div className="overview content-practice-single mg-practice-single">
                  <h3 className="title">
                    <a href="#">{title}</a>
                  </h3>
                  <p>{content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
