import React from "react";
import { Link } from "react-router-dom";
import AboutDesc from "../../components/about-desc";
import Attorneys from "../../components/attorneys";
import Form from "../../components/contact-form";
import Featured from "../../components/featured";
import Iconbox from "../../components/iconbox";
import News from "./news";
const Home = () => {
  return (
    <>
      <div className="flat-slider">
        <div className="fullwidthbanner-container">
          <div className="fullwidthabanner">
            <div className="container main-content ">
              <h3>STRATEGY. EXPERIENCE. RESULTS.</h3>
              <h1>Patience Imoisili Chambers</h1>
              <p>We Are The Attorneys For You.</p>
              <Link to="/about" className=" Learn_more_btn hvr-vertical">
                Learn More
                <div className="border-animate">
                  <div className="top"></div>
                  <div className="right"></div>
                  <div className="bottomb"></div>
                  <div className="left"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Iconbox />
      <Featured />

      <div className="contact-us">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="go-up">
                <div
                  className="themesflat-content-box"
                  data-padding="0% 0% 0% 0%"
                  data-sdesktoppadding="0% 0% 0% 0%"
                  data-ssdesktoppadding="0% 0% 0% 0%"
                  data-mobipadding="100px 0% 0% 0%"
                  data-smobipadding="100px 0% 0% 0%"
                >
                  <Form />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-evaluation">
                <div
                  className="themesflat-content-box"
                  data-padding="113px 0% 101px 100px"
                  data-sdesktoppadding="113px 0% 101px 0"
                  data-ssdesktoppadding="113px 0% 101px 0"
                  data-mobipadding="50px 0 100px 0"
                  data-smobipadding="50px 0 100px 0"
                >
                  <div className="caption">Get quick response</div>
                  <h4 className="heading">
                    Get a quick response and legal advice from an expert
                  </h4>

                  <div
                    className="flat-spacer"
                    data-desktop="90"
                    data-sdesktop="60"
                    data-mobi="25"
                    data-smobi="25"
                  ></div>
                  <div className="call-us">
                    <div className="text">Or Call us</div>
                    <h3>
                      <a
                        href="tel:+2349158685156"
                        className="phone-number text-white"
                      >
                        09158685156
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <News />
    </>
  );
};

export default Home;
