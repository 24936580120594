import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../features.js/slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AdminLogin = () => {
  const [adminName, setAdminName] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (adminName === "admin" && adminPassword === "1234") {
      localStorage.setItem("isLoggedIn", true);
      dispatch(login());
      toast.success("Welcome Back Admin");
    } else {
      alert("Incorrect admin name or password");
    }
  };

  return (
    <>
      <ToastContainer />
      <form
        action="#"
        className="container form-evaluation my-5"
        onSubmit={handleSubmit}
      >
        <div className="wrap-evaluation">
          <h5 className="title">Admin Access</h5>
          <div className="your-name mg-text">
            <input
              type="text"
              className="Admin"
              placeholder="Admin name"
              onChange={(e) => setAdminName(e.target.value)}
            />
          </div>
          <div className="your-phone mg-text">
            <input
              type="text"
              className="Admin-password"
              placeholder="Admin Password"
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
            />
          </div>
          <div className="fl-btn">
            <button className="hvr-vertical">Login As Admin</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AdminLogin;
