import React from "react";

const AboutDesc = () => {
  return (
    <>
      <div className="about about-style1">
        <div className="container">
          <div className="text-content">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="heading">ABOUT US</div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <p>
                  Patience Imoisili Chambers is a full service litigation law
                  firm with vast years of experience in Court room Litigation.
                  It's name is derived in memory of the late Mrs. Patience
                  Imoisili, the beloved mother of the Managing Partner - Mr.
                  Abraham Imoisili.
                </p>
                <p>
                  We pride ourselves as a leading litigation law firm and
                  industrial thought leaders in several areas of law ranging
                  from contract law, commercial law, election petitions and
                  pre-election matters, fundamental humans rights enforcement
                  and Constitutional law, family law/inheritance/Child
                  rights/dissolution of marriage, land law/real estate dispute
                  as well as labour and employment law.
                </p>
                <p>
                  Our Key Performance Indicators are client satisfaction, timely
                  delivery and outstanding results.
                </p>
                <p>
                  We also conduct background checks, due diligence, verification
                  and preliminary investigations into information supplied to
                  our clients in all sectors.
                </p>
                <p>
                  Our client base cut across Co-operative societies, commercial
                  banks, Microfinance banks, political parties, human rights
                  organizations and NGOs etc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutDesc;
