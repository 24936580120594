import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./ui/pages/home";
import About from "./ui/pages/about";
import Articles from "./ui/pages/articles";
import ArticleDesc from "./ui/pages/articledesc";
import AdminLogin from "./ui/pages/admin/adminLogin";
import AdminDashboard from "./ui/pages/admin/adminDashboard";
import { Provider } from "react-redux";
import store from "./features.js/store";
import Admin from "./ui/pages/admin/admin";
import AttorneyCv from "./ui/pages/attorneyCv";
import News from "./ui/pages/news";
import NewDesc from "./ui/pages/newDesc";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<App />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/articledesc/:id" element={<ArticleDesc />} />
            <Route path="/news" element={<News />} />
            <Route path="/newsdesc/:id" element={<NewDesc />} />
            <Route path="/admin" exact element={<Admin />} />
            <Route path="/attorneycv/:name" exact element={<AttorneyCv />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
