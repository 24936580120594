import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-widgets">
          <div className="container">
            <div className="footer-top">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-6 ">
                  <div className="widget widget-text mg-footer-mbb">
                    <div className="content">
                      <div className="images-logo">
                        <img src="/logo.png" alt="images" />
                      </div>
                      <p className="text-dark">
                        Patience Imoisili Chambers is a full service litigation
                        law firm with vast years of experience in Court room
                        Litigation. It's name is derived in memory of the late
                        Mrs. Patience Imoisili, the beloved mother of the
                        Managing Partner.
                      </p>
                      <div className="list-socials">
                        <a href="https://facebook.com/PatienceImoisiliChambers">
                          <i
                            className="fa-brands fa-facebook text-dark"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a href="https://www.instagram.com/p.i.c.legal/">
                          <i
                            className="fa-brands fa-instagram text-dark"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a href="https://wa.me/+2349158685156">
                          <i
                            className="fa-brands fa-whatsapp text-dark"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-6">
                  <div className="widget widget-contact mg-footer-mbb">
                    <h2 className="widget-title text-dark">Contact</h2>
                    <div className="content">
                      <ul>
                        <li>
                          <span className="text address text-dark">
                            Address: 32, Adegbola Street Anifowoshe Ikeja Lagos
                          </span>
                        </li>
                        <li>
                          <span>
                            <a
                              className="text phone text-dark"
                              href="tel:+2349158685156"
                            >
                              09158685156
                            </a>
                          </span>
                        </li>
                        <li>
                          <a
                            href="mailto:contact@patienceimoisilichambers.com"
                            className="text email text-dark"
                          >
                            contact@patienceimoisilichambers.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget-services mg-footer-mbb">
                    <div className="content">
                      <ul className="widget-menu">
                        <li>
                          <Link to="/about" className="text-dark">
                            About us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="container">
              <div className="row">
                <Link to="/admin">
                  <p className="mx-3 text-dark">Admin </p>
                </Link>
                <p className="mx-3  text-dark">| © 2023 All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
