import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../components/useFetch";
import { useSelector } from "react-redux";
import articleDatas from "../../sitedata/articlesData/articleData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ArticleDesc = () => {
  // const { isLoggedIn } = useSelector((state) => state.isLoggedIn);
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const { id } = useParams();
  const {
    data: eachArticle,
    isPending,
    error,
  } = useFetch(`https://chamber-articles-api.onrender.com/articles/${id}`);
  // } = useFetch(`http://localhost:3002/articles/${id}`);
  console.log(eachArticle);

  const paragraph =
    eachArticle &&
    eachArticle.content
      .split("\n")
      .map((paragraph, index) => <p key={index}>{paragraph}</p>);
  const navigate = useNavigate();

  const handleDelete = (e) => {
    e.preventDefault();
    console.log(isLoggedIn);
    fetch(`https://chamber-articles-api.onrender.com/articles/${id}`, {
      // fetch(`http://localhost:3002/articles/${id}`, {
      method: "DELETE",
    }).then(() => {
      toast.success(`Article with id:${id} deleted successfully`);
      navigate("/articles");
      console.log(id);
    });
  };
  const defaultImg =
    "https://images.unsplash.com/photo-1593115057322-e94b77572f20?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80";
  return (
    <>
      {isPending && <h1>Articles Loading...</h1>}
      {error && <h1>{error.message}</h1>}
      {eachArticle && (
        <div className="practice-single">
          <div className="container">
            {isLoggedIn ? (
              <button className="my-2" onClick={handleDelete}>
                Delete Article
              </button>
            ) : null}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="content-wrap clearfix">
                  <div className="featured-post">
                    <div className="entry-image">
                      {eachArticle.imgUrl == "" ? (
                        <img src={defaultImg} alt="images" />
                      ) : (
                        <img src={eachArticle.imgUrl} alt="images" />
                      )}
                    </div>
                  </div>
                  <div className="overview content-practice-single mg-practice-single">
                    <h3 className="title">
                      <a href="#">{eachArticle.title}</a>
                    </h3>
                    <p>{paragraph}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArticleDesc;
